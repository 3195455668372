import React from "react"

import * as styles from "./footer.module.css"

const Footer = () => (
  <footer>
    <div className={styles.column}>
      <p className={styles.h1}>Colegio Charles Darwin</p>
      <p className={styles.text}>Ejido 1006, Javier Rojo Gómez, 42030 Pachuca de Soto, Hgo. (a un costado de Prepa 3)</p>
      <p className={styles.text}>Teléfono: <a className={styles.a} href="tel:771-107-0819">771-107-1809</a></p>
      <p className={styles.text}>Celular: <a className={styles.a} href="tel:771-608-9167">771-608-9167</a></p>
      <p className={styles.text}><a className={styles.a} href="mailto:colegio@charles-darwin.edu.mx">colegio@charles-darwin.edu.mx</a></p>
    </div>
  </footer>
)

export default Footer
