import React from "react"
import { Link } from "gatsby"

import logo from "../images/icon.png"
import * as styles from "./header.module.css"

const Header = () => {
  return (
    <header>
      <nav className={styles.nav}>
        <Link to="/">
          <img className={styles.logo} src={logo} alt="Colegio Charles Darwin" />
        </Link>
      </nav>
      <p className={styles.notice}>CLASES ONLINE</p>
    </header>
  )
}

export default Header
